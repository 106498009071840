import { defineStore } from 'pinia'
import mitt from 'mitt';

interface State {
    emitter: any
}

export const useLocalNotificationsStore = defineStore('LocalNotificationsStore', {
    state: (): State => ({
        emitter: mitt(),
    }),
    actions: {
        addObserver(observer) {
            this.emitter.on(observer.topic, observer.callback);
        },
        addLocalNotificationsObserver(observer) {
            this.emitter.on('local-notification', observer.callback);
        },
        publish(notification) {
            this.emitter.emit(notification.topic, notification.notification);
        },
        publishLocalNotification(notification) {
            this.emitter.emit('local-notification', notification);
        }
    }
});