import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { useAuthStore } from "@/core/stores/AuthStore";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/map",
    component: () => import("@/layout/MapLayout.vue"),
    children: [
      {
        path: "/map",
        name: "map",
        component: () => {
          if (window.innerWidth >= 1024) {
            return import("@/views/map/desktop/Map.vue");
          } else {
            return import("@/views/map/mobile/Map.vue");
          }
        }
      }
    ]
  },
  {
    path: "/summary",
    component: () => import("@/layout/Layout.vue"),
    redirect: "/summary/period",
    children: [
      {
        path: "/summary/period",
        name: "period-summary",
        component: () => import("@/views/summary/period/PeriodSummary.vue")
      },
      {
        path: "/summary/manager",
        name: "manager-summary",
        component: () => import("@/views/summary/manager/ManagerSummary.vue")
      },
      {
        path: "/summary/driver",
        name: "driver-summary",
        component: () => import("@/views/summary/driver/DriverSummary.vue")
      }
    ]
  },
  {
    path: "/history",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/history",
        name: "history",
        component: () => import("@/views/history/History.vue")
      }
    ]
  },
  {
    path: "/tachograph",
    component: () => import("@/layout/Layout.vue"),
    redirect: "/tachograph/files",
    children: [
      {
        path: "/tachograph/files",
        name: "tachograph-files",
        component: () => import("@/views/tachograph/TachographFiles.vue")
      }
    ]
  },
  {
    path: "/settings",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/settings/users",
        name: "users-list",
        component: () => import("@/views/settings/users/List.vue"),
      },
      {
        path: "/settings/users/new",
        name: "users-create",
        component: () => import("@/views/settings/users/Add.vue"),
      },
      {
        path: "/settings/users/edit/:id",
        name: "users-edit",
        component: () => import("@/views/settings/users/Edit.vue"),
      },
      {
        path: "/settings/organizations",
        name: "organizations-list",
        component: () => import("@/views/settings/organizations/List.vue"),
      },
      {
        path: "/settings/organizations/new",
        name: "organizations-create",
        component: () => import("@/views/settings/organizations/Add.vue"),
      },
      {
        path: "/settings/organizations/edit/:id",
        name: "organizations-edit",
        component: () => import("@/views/settings/organizations/Edit.vue"),
      },
      {
        path: "/settings/vehicles",
        name: "vehicles-list",
        component: () => import("@/views/settings/vehicles/List.vue"),
      },
      {
        path: "/settings/vehicles/new",
        name: "vehicles-create",
        component: () => import("@/views/settings/vehicles/Add.vue"),
      },
      {
        path: "/settings/vehicles/edit/:id",
        name: "vehicles-edit",
        component: () => import("@/views/settings/vehicles/Edit.vue"),
      },
      {
        path: "/settings/navigation-units",
        name: "navigation-units-list",
        component: () => import("@/views/settings/navigation-units/List.vue"),
      },
      {
        path: "/settings/navigation-units/new",
        name: "navigation-units-create",
        component: () => import("@/views/settings/navigation-units/Add.vue"),
      },
      {
        path: "/settings/navigation-units/edit/:id",
        name: "navigation-units-edit",
        component: () => import("@/views/settings/navigation-units/Edit.vue"),
      },
      {
        path: "/settings/app-versions",
        name: "app-versions-list",
        component: () => import("@/views/settings/app-versions/List.vue"),
      },
      {
        path: "/settings/app-versions/new",
        name: "app-versions-create",
        component: () => import("@/views/settings/app-versions/Add.vue"),
      },
      {
        path: "/settings/app-versions/edit/:id",
        name: "app-versions-edit",
        component: () => import("@/views/settings/app-versions/Edit.vue"),
      },
      {
        path: "/settings/fast-chat-questions",
        name: "fast-chat-questions-list",
        component: () => import("@/views/settings/fast-chat-questions/List.vue"),
      },
      {
        path: "/settings/fast-chat-questions/new",
        name: "fast-chat-questions-create",
        component: () => import("@/views/settings/fast-chat-questions/Add.vue"),
      },
      {
        path: "/settings/fast-chat-questions/edit/:id",
        name: "fast-chat-questions-edit",
        component: () => import("@/views/settings/fast-chat-questions/Edit.vue"),
      },
      {
        path: "/settings/fast-chat-messages",
        name: "fast-chat-messages-list",
        component: () => import("@/views/settings/fast-chat-messages/List.vue"),
      },
      {
        path: "/settings/fast-chat-messages/new",
        name: "fast-chat-messages-create",
        component: () => import("@/views/settings/fast-chat-messages/Add.vue"),
      },
      {
        path: "/settings/fast-chat-messages/edit/:id",
        name: "fast-chat-messages-edit",
        component: () => import("@/views/settings/fast-chat-messages/Edit.vue"),
      },
      // {
      //   path: "/settings/app-versions/edit/:id",
      //   name: "app-versions-edit",
      //   component: () => import("@/views/settings/app-versions/Edit.vue"),
      // },
    ]
  },
  {
    path: "/",
    component: () => import("@/layout/AuthLayout.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () =>
          import("@/views/auth/Login.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error-pages/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error-pages/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  {
    path: "/debug/:identifier/:licensePlate",
    name: 'debug-vehicle-params',
    component: () => import("@/views/debug/DebugParameters.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, _, next) => {
  const authStore = useAuthStore();
  const isLoggedIn = authStore.isAuthenticated;

  if (!isLoggedIn && to.name !== 'login') {
    return next({ name: 'login' })
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  return next();
});

export default router;
