import { createApp } from "vue";
import { createPinia } from 'pinia'
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import VueCookies from 'vue3-cookies'

// Import TailwindCSS
import "@/assets/css/tailwind/input.css";

//imports for app initialization
// import MockAdapter from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import "bootstrap";

// Tooltips
import VueTippy, { plugin } from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'


// window.$.cookie = useCookies();
const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(ElementPlus);
app.use(VueCookies, {
    expireTimes: "30d",
});

ApiService.init(app);
initInlineSvg(app);
initVeeValidate();

declare global {
    interface Window { $: any; jQuery: any; }
};

window.$ = window.jQuery = require('jquery');

import { useCookies } from "vue3-cookies";
import { useWebNotificationsStore } from "./core/stores/WebNotificationsStore";
import { useAppStore } from "./core/stores/AppStore";
window.$.cookie = useCookies();

app.use(i18n);

// START: Enable vue-toastification plugin

app.use(Toast, {
    filterBeforeCreate: (toast, toasts) => {
        const sameToasts = toasts.filter((t) => {
            return t.type === toast.type && t.content === toast.content
        });

        if (sameToasts.length !== 0) {
          // Returning false discards the toast
          return false;
        }

        // You can modify the toast if you want
        return toast;
      }
});
// END: Enable vue-toastification plugin

// START: Enable tippy plugin

app.use(
    VueTippy,
    // optional
    {
        directive: 'tippy', // => v-tippy
        component: 'tippy', // => <tippy/>
        componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
        defaultProps: {
            placement: 'bottom',
            allowHTML: true,
            theme: 'light'
        }, // => Global default options * see all props
    }
);
// END: Enable tippy plugin

app.mount("#app");

document.addEventListener("DOMContentLoaded", () => {
    const webNotificationsStore = useWebNotificationsStore();
    webNotificationsStore.setup();

    const appStore = useAppStore();
    appStore.setup();
});
