import { defineStore } from 'pinia'
import i18n from '../plugins/i18n';

interface State {
    newMessageSound: HTMLAudioElement,
    lastNewMessageSoundPlayTimestamp: number | null,
    defaultWebTitle: string,
    hasChangedTitle: boolean,
}

export const useWebNotificationsStore = defineStore('WebNotificationsStore', {
    state: (): State => ({
        newMessageSound: new Audio('/media/sounds/new-message.mp3'),
        lastNewMessageSoundPlayTimestamp: null,
        defaultWebTitle: 'Vestigo',
        hasChangedTitle: false
    }),
    actions: {
        _playNewMessageSound() {
            if (this.lastNewMessageSoundPlayTimestamp != null) {
                const diff = new Date().getTime() - this.lastNewMessageSoundPlayTimestamp;
                if (diff < 5 * 1000) {
                    return;
                }
            }
            
            this.newMessageSound.play();
            this.lastNewMessageSoundPlayTimestamp = new Date().getTime();
        },
        _updateWebTitle(message: string) {
            document.title = `${message} | ${this.defaultWebTitle}`;
            this.hasChangedTitle = true;
        },
        _resetWebTitle() {
            document.title = this.defaultWebTitle;
            this.hasChangedTitle = false;
        },
        _onPageVisibilityChanged() {
            if (document.hidden || !this.hasChangedTitle) {
                return;
            }

            this._resetWebTitle();
        },
        onNewMessageReceived() {
            this._playNewMessageSound();

            if (document.hidden && !this.hasChangedTitle) {
                this._updateWebTitle(i18n.global.t('page_title_new_messages'));
            }
        },
        setup() {
            document.addEventListener("visibilitychange", (event) => {
                this._onPageVisibilityChanged()
            });
        }
    }
});