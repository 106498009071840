import { defineStore } from 'pinia'
import { useAPIStore } from '@/core/stores/APIStore';
import { ChatAttendee, Organization, User } from '@/core/models/Models';

const ID_TOKEN_KEY = "id_token" as string;

interface State {
    _token: string | null,
    user: User | undefined,
    organizations: Array<Organization> | undefined, 
}

export const useAuthStore = defineStore('AuthStore', {
    state: (): State => ({
        _token: window.localStorage.getItem(ID_TOKEN_KEY),
        user: undefined,
        organizations: undefined
    }),
    getters: {
        isAuthenticated(state): boolean {
            return state._token != null;
        },
        token(state): string | null {
            return state._token;
        },
        chatAttendee(state): ChatAttendee | undefined {
            if (!state.user) {
                return undefined;
            }

            return {
                _id: `u_${state.user.id}`,
                name: state.user.name,
                role: state.user.role_name ?? "-"
            }
        },
        canCreateViaPointTemplates(state): boolean {
            // TODO: Check if user has appropriate rules
            return state.user != undefined;
        },
        canDeleteViaPointTemplates(state): boolean {
            // TODO: Check if user has appropriate rules
            return state.user != undefined;
        },
    },
    actions: {
        // Private actions
        _saveToken(token) {
            if (!token) {
                window.localStorage.removeItem(ID_TOKEN_KEY);
            } else {
                window.localStorage.setItem(ID_TOKEN_KEY, token);
            }
        },
        async _fetchUser() {
            const apiStore = useAPIStore();

            return apiStore.getCurrentUser().then((response) => {
                this.user = {
                    id: response.id,
                    name: response.name,
                    email: response.email,
                    organization_id: response.organization_id,
                    organization: response.organization,
                    roles: response.roles,
                    role_name: response.role_name
                };

                return response;
            });
        },
        async _fetchOrganizations() {
            const apiStore = useAPIStore();

            return apiStore.getCurrentUsersOrganizations().then((response) => {
                this.organizations = response;

                return response;
            });
        },
        // Public actions
        setToken(token) {
            this._saveToken(token);
            this._token = token;
        },
        purgeAuth() {
            this.setToken(null);
            this.user = undefined;
        },
        hasRole(role: string): boolean {
            return this.user?.roles?.includes(role) ?? false;
        },
        async initialize() {
            if (this.isAuthenticated && this.user == undefined) {
                this._fetchUser();
                this._fetchOrganizations();
            }
        },
        async login(credentials) {
            const apiStore = useAPIStore();

            return apiStore.login(credentials).then((response) => {
                if (response.organizations) {
                    return response;
                } else {
                    this.setToken(response.token);
                    return this.initialize().then(() => {
                        return null;
                    });
                }
            });
        },
        async switchOrganization(organization: Organization) {
            const apiStore = useAPIStore();

            return apiStore.switchOrganization(organization).then((response) => {
                window.location.reload();
            });
        },
        logout() {
            this.purgeAuth();
        },
    }
});