import { defineStore } from 'pinia'

interface State {
    ablyHasDisconnected: boolean
}

export const useAppStore = defineStore('AppStore', {
    state: (): State => ({
        ablyHasDisconnected: false
    }),
    getters: {
        shouldReload(state: State): boolean {
            return this.ablyHasDisconnected;
        }
    },
    actions: {
        _onPageVisibilityChanged() {
            if (document.hidden || !this.shouldReload) {
                return;
            }

            location.reload();
        },
        setup() {
            document.addEventListener("visibilitychange", (event) => {
                console.log(`[AppStore] On page visibility did change to: ${document.hidden}`);
                this._onPageVisibilityChanged()
            });
        }
    }
});